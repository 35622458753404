<template>
  <div class="object" :class="type">
    <div class="image" :style="{width,height}">
      <img :src="img" alt />
      <div class="category" v-if="type=='icon'">
        <div class="icon" v-if="icon">
          <img :src="icon" />
        </div>
        <span>{{category}}</span>
      </div>
    </div>
    <div class="title" v-if="title">
      <span>{{title}}</span>
    </div>
    <div class="category" v-if="category && type!=='icon'">
      <span>{{category}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ObjectCard",
  props: {
    type: {
      // icon
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    category: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "300px"
    },
    height: {
      type: String,
      default: "200px"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.object {
  .image {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    background: $color_grey_3;
    transition: all 0.3s ease-in;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .category {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: 78px;
      padding-left: 12px;
      padding-top: 22px;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.11px;
      color: rgba(#fff, 0.8);
      background: linear-gradient(
        to bottom,
        rgba(#000, 0),
        rgba(#000, 0.81) 88%
      );
      .icon {
        margin-right: 12px;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &:active .image {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }

  .title {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: -0.83px;
    color: rgba(#fff, 0.8);
    margin-top: 12px;
  }
  &.icon .title {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: -1px;
    color: rgba(#fff, 0.97);
  }

  .category {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.08px;
    color: $color_grey_a6;
    text-transform: uppercase;
    margin-top: 4px;
  }
}
</style>